$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';
@import '../CalendarModal.module';

/**
 * Estructura DOM de un día: .rmpd-day > .sd > button > div (icono + texto)
 */

/**
 * Estructura DOM de un día deshabilitado: .rmpd-day > " " > button > div (texto)
 */

/**
 * Estilos cuando no tenemos rango o sólo tenemos el checkin
 */
.day {
  :global {
    // Día de la semana
    .rmdp-day {
      @include font-s-700;

      flex-grow: 1;

      width: $dayWidth;
      height: $dayHeight;

      .sd {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        margin: 0 auto;
        border-radius: unset;
        width: $dayWidth;
        height: $dayHeight;
        padding: 0;

        &:hover {
          background-color: var(--b-color-fill-neutral-04);
        }
      }

      button {
        color: var(--b-color-text-dark);
        cursor: pointer;
      }
    }

    // Día de la semana que esté deshabilitado -> en este caso en vez de tener una clase llamada sd es una clase vacía por lo que hay que acceder al span directamente
    .rmdp-day.rmdp-disabled span {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      // Quitar el color a los dots del rango de precios
      button > .dots > * {
        background-color: unset;
      }
    }

    .rmdp-today button span {
      color: unset;
      background-color: unset;
    }

    // Día de hoy que no esté en un rango, es decir, que no sea checkIn
    .rmdp-today:not(.rmdp-range) .sd {
      border: toRem(1) solid var(--b-color-interactive-secondary-enabled);
      border-radius: toRem(3);

      background-color: unset;

      button {
        color: var(--b-color-text-primary);
      }

      &:hover {
        border: unset;
      }
    }

    // Rango de selección (sólo para resetear estilos por defecto)
    .rmdp-range {
      box-shadow: unset;

      background-color: unset;
    }

    // Rango de selección cuando aún no hay checkOut (el usuario está haciendo hover por los días)
    .rmdp-range-hover {
      color: var(--b-color-text-dark);
      background-color: var(--b-color-interactive-primary-selected);

      // Aplicar el color negro a los dots del rango de precios
      button > .dots > * {
        background-color: var(--b-color-fill-neutral-04);
      }
    }

    // Todos los span de día sobre los que se haga hover excepto si el día no se muestra (está el hueco en blanco), es un rango, es el hover del checkOut o del checkIn o está deshabilitado.
    .rmdp-day:not(
        .rmdp-day-hidden,
        .rmdp-range,
        .rmdp-range-hover.end,
        .rmdp-range-hover.start,
        .rmdp-disabled
      ) {
      &:hover {
        .sd {
          border-radius: toRem(3) 0 0 toRem(3);

          color: var(--b-color-text-dark);
          background-color: var(--b-color-interactive-primary-hover);

          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: $dayWidth;

            border-bottom: calc($dayHeight / 2) solid transparent;
            border-top: calc($dayHeight / 2) solid transparent;
            border-left: toRem(4) solid var(--b-color-interactive-primary-hover);

            background-color: var(--b-color-bg-white);

            @media #{$fromTablet} {
              border-left: toRem(6) solid
                var(--b-color-interactive-primary-hover);
            }

            [dir='rtl'] & {
              right: $dayWidth;
              left: unset;

              border-left: unset;
              border-right: toRem(6) solid
                var(--b-color-interactive-primary-hover);
            }
          }

          // Aplicar el color negro a los dots del rango de precios
          button > .dots > div {
            background-color: var(--b-color-fill-neutral-04);
          }
        }
      }
    }

    // Si el día está oculto (hay un hueco en el mes), deshabilita cualquier evento porque se hacía hover los días del siguiente mes
    .rmdp-day.rmdp-day-hidden {
      pointer-events: none;
    }
  }
}

/**
 * Estilos del día de checkIn cuando el usuario ha seleccionado checkIn pero no checkOut
 */
.selectedCheckIn {
  :global {
    // Día del checkIn
    .rmdp-range {
      .sd {
        border-radius: toRem(3) 0 0 toRem(3);

        background-color: var(--b-color-fill-neutral-04);

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: $dayWidth;

          border-bottom: calc($dayHeight / 2) solid transparent;
          border-top: calc($dayHeight / 2) solid transparent;
          border-left: toRem(4) solid var(--b-color-fill-neutral-04);

          @media #{$fromTablet} {
            border-left: toRem(6) solid var(--b-color-fill-neutral-04);
          }

          [dir='rtl'] & {
            right: $dayWidth;
            left: unset;

            border-left: unset;
            border-right: toRem(6) solid var(--b-color-fill-neutral-04);
          }
        }
        path {
          stroke: var(--b-color-bg-white);
        }
      }

      //Si su siguiente un hermano contiene la clase .rmdp-range-hover => aplica el background-color
      &:has(+ .rmdp-range-hover) .sd::after {
        width: calc($dayHeight / 2);

        background-color: var(--b-color-interactive-primary-selected);

        @media #{$fromTablet} {
          border-right: toRem(6) solid var(--b-color-interactive-primary-hover);
          width: calc(100% + $dayHeight / 2);
        }

        @media #{$fromLaptop} {
          width: toRem(5.6);
        }
      }

      button {
        color: var(--b-color-text-light);

        // Aplicar el color blanco a los dots del rango de precios
        & > .dots > * {
          background-color: var(--b-color-fill-neutral-00);
        }
      }
    }

    // Estilos del hover del checkOut (el usuario ha hecho checkIn pero aun no ha hecho checkOut)
    .rmdp-range-hover.end {
      color: var(--b-color-text-dark);
      background-color: unset;

      .sd {
        border-radius: 0 toRem(3) toRem(3) 0;

        color: var(--b-color-text-dark);
        background-color: var(--b-color-fill-ocean-10);

        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: $dayWidth;

          border-bottom: calc($dayHeight / 2) solid transparent;
          border-top: calc($dayHeight / 2) solid transparent;
          border-right: toRem(4) solid var(--b-color-interactive-primary-hover);
          width: fit-content;

          background-color: var(--b-color-interactive-primary-selected);

          @media #{$fromTablet} {
            border-right: toRem(6) solid
              var(--b-color-interactive-primary-hover);
            width: calc(100% - $dayHeight / 15);
          }

          @media #{$fromLaptop} {
            width: toRem(5.6);
          }

          [dir='rtl'] & {
            right: unset;
            left: $dayWidth;

            border-left: toRem(6) solid var(--b-color-interactive-primary-hover);
            border-right: unset;
          }
        }
      }

      // Si el hover del ckeckOut es el primer día de la semana no le pone fondo
      &:first-child .sd::before {
        background-color: var(--b-color-bg-white);
      }

      // Aplicar el color negro a los dots del rango de precios
      button > .dots > * {
        background-color: var(--b-color-fill-neutral-04);
      }
    }

    // Hover del rango cuando es el primer día de la semana y éste no es checkIn ni checkOut
    .rmdp-range-hover:first-child:not(.start, .end) {
      background: linear-gradient(
        90deg,
        transparent 0%,
        transparent 15%,
        var(--b-color-interactive-primary-selected) 15%,
        var(--b-color-interactive-primary-selected) 100%
      );

      [dir='rtl'] & {
        background: linear-gradient(
          90deg,
          var(--b-color-interactive-primary-selected) 0%,
          var(--b-color-interactive-primary-selected) 85%,
          transparent 85%,
          transparent 100%
        );
      }

      @media #{$fromTablet} {
        background: linear-gradient(
          90deg,
          transparent 0%,
          transparent 30%,
          var(--b-color-interactive-primary-selected) 30%,
          var(--b-color-interactive-primary-selected) 100%
        );

        [dir='rtl'] & {
          background: linear-gradient(
            90deg,
            var(--b-color-interactive-primary-selected) 0%,
            var(--b-color-interactive-primary-selected) 70%,
            transparent 70%,
            transparent 100%
          );
        }
      }

      @media #{$fromLaptop} {
        background: linear-gradient(
          90deg,
          transparent 0%,
          transparent 10%,
          var(--b-color-interactive-primary-selected) 10%,
          var(--b-color-interactive-primary-selected) 100%
        );

        [dir='rtl'] & {
          background: linear-gradient(
            90deg,
            var(--b-color-interactive-primary-selected) 0%,
            var(--b-color-interactive-primary-selected) 90%,
            transparent 90%,
            transparent 100%
          );
        }
      }
    }

    // Hover del rango cuando es el último día de la semana y éste no es checkIn ni checkOut
    .rmdp-range-hover:last-child:not(.start, .end) {
      background: linear-gradient(
        90deg,
        var(--b-color-interactive-primary-selected) 0%,
        var(--b-color-interactive-primary-selected) 85%,
        transparent 85%,
        transparent 100%
      );

      [dir='rtl'] & {
        background: linear-gradient(
          90deg,
          transparent 0%,
          transparent 15%,
          var(--b-color-interactive-primary-selected) 15%,
          var(--b-color-interactive-primary-selected) 100%
        );
      }

      @media #{$fromTablet} {
        background: linear-gradient(
          90deg,
          var(--b-color-interactive-primary-selected) 0%,
          var(--b-color-interactive-primary-selected) 70%,
          transparent 70%,
          transparent 100%
        );

        [dir='rtl'] & {
          background: linear-gradient(
            90deg,
            transparent 0%,
            transparent 30%,
            var(--b-color-interactive-primary-selected) 30%,
            var(--b-color-interactive-primary-selected) 100%
          );
        }
      }

      @media #{$fromLaptop} {
        background: linear-gradient(
          90deg,
          var(--b-color-interactive-primary-selected) 0%,
          var(--b-color-interactive-primary-selected) 90%,
          transparent 90%,
          transparent 100%
        );

        [dir='rtl'] & {
          background: linear-gradient(
            90deg,
            transparent 0%,
            transparent 10%,
            var(--b-color-interactive-primary-selected) 10%,
            var(--b-color-interactive-primary-selected) 100%
          );
        }
      }
    }
  }
}

/**
 * Estilos cuando ya tenemos un rango seleccionado
 */
.selectedDays {
  :global {
    // Días del rango
    .rmdp-range {
      box-shadow: unset;

      color: var(--b-color-text-dark);
      background-color: var(--b-color-interactive-primary-selected);

      .sd {
        background-color: unset;
        path {
          stroke: var(--b-color-icon-dark);
        }
      }

      // Aplicar el color negro a los dots del rango de precios
      button > .dots > * {
        background-color: var(--b-color-fill-neutral-04);
      }
    }

    // Hover de los días del rango que no son ni checkIn ni checkOut, ni están deshabilitados, ni ocultos.
    .rmdp-range:not(.rmdp-disabled, .rmdp-day-hidden, .start, .end) .sd:hover {
      background-color: unset;
    }

    // Días del rango que no son ni checkIn ni checkOut
    .rmdp-range:not(.end, .start) {
      button {
        background-color: unset;
        color: var(--b-color-text-dark);
      }
    }

    .rmdp-range.start,
    .rmdp-range.end {
      button {
        color: var(--b-color-text-light);

        // Aplicar el color blanco a los dots del rango de precios
        & > .dots > * {
          background-color: var(--b-color-fill-neutral-00);
        }
      }

      .sd {
        background-color: var(--b-color-fill-neutral-04);
      }

      path {
        stroke: var(--b-color-bg-white);
      }
    }

    // Día de checkIn
    .rmdp-range.start {
      background: linear-gradient(
        90deg,
        transparent 0%,
        transparent 49%,
        var(--b-color-interactive-primary-selected) 49%,
        var(--b-color-interactive-primary-selected) 100%
      );

      [dir='rtl'] & {
        background: linear-gradient(
          90deg,
          var(--b-color-interactive-primary-selected) 0%,
          var(--b-color-interactive-primary-selected) 49%,
          transparent 49%,
          transparent 100%
        );
      }

      .sd {
        border-radius: toRem(3) 0 0 toRem(3);

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: $dayWidth;

          border-bottom: calc($dayHeight / 2) solid transparent;
          border-top: calc($dayHeight / 2) solid transparent;
          border-left: toRem(4) solid var(--b-color-fill-neutral-04);

          @media #{$fromLaptop} {
            border-left: toRem(6) solid var(--b-color-fill-neutral-04);
          }

          [dir='rtl'] & {
            right: $dayWidth;
            left: unset;

            border-left: unset;
            border-right: toRem(6) solid var(--b-color-fill-neutral-04);
          }
        }
      }

      // Si el checkIn es el último día de la semana no le pone fondo
      &:last-child {
        background: unset;

        .sd::after {
          background-color: unset;
        }
      }
    }

    //Día de checkOut
    .rmdp-range.end {
      background: linear-gradient(
        90deg,
        var(--b-color-interactive-primary-selected) 0%,
        var(--b-color-interactive-primary-selected) 49%,
        transparent 49%,
        transparent 100%
      );

      [dir='rtl'] & {
        background: linear-gradient(
          90deg,
          transparent 0%,
          transparent 49%,
          var(--b-color-interactive-primary-selected) 49%,
          var(--b-color-interactive-primary-selected) 100%
        );
      }

      .sd {
        border-radius: 0 toRem(3) toRem(3) 0;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: $dayWidth;

          border-bottom: calc($dayHeight / 2) solid transparent;
          border-top: calc($dayHeight / 2) solid transparent;
          border-right: toRem(4) solid var(--b-color-fill-neutral-04);

          @media #{$fromLaptop} {
            border-right: toRem(6) solid var(--b-color-fill-neutral-04);
          }

          [dir='rtl'] & {
            right: unset;
            left: $dayWidth;

            border-left: toRem(6) solid var(--b-color-fill-neutral-04);
            border-right: unset;
          }
        }
      }

      // Si el checkOut es el primer día de la semana no le pone fondo
      &:first-child {
        background: unset;

        .sd::before {
          background-color: unset;
        }
      }
    }

    // Día del rango cuando es el primer día de la semana y éste no es checkIn ni checkOut
    .rmdp-range:first-child:not(.start, .end) {
      background: linear-gradient(
        90deg,
        transparent 0%,
        transparent 15%,
        var(--b-color-interactive-primary-selected) 15%,
        var(--b-color-interactive-primary-selected) 100%
      );

      [dir='rtl'] & {
        background: linear-gradient(
          90deg,
          var(--b-color-interactive-primary-selected) 0%,
          var(--b-color-interactive-primary-selected) 85%,
          transparent 85%,
          transparent 100%
        );
      }

      @media #{$fromTablet} {
        background: linear-gradient(
          90deg,
          transparent 0%,
          transparent 30%,
          var(--b-color-interactive-primary-selected) 30%,
          var(--b-color-interactive-primary-selected) 100%
        );

        [dir='rtl'] & {
          background: linear-gradient(
            90deg,
            var(--b-color-interactive-primary-selected) 0%,
            var(--b-color-interactive-primary-selected) 70%,
            transparent 70%,
            transparent 100%
          );
        }
      }

      @media #{$fromLaptop} {
        background: linear-gradient(
          90deg,
          transparent 0%,
          transparent 10%,
          var(--b-color-interactive-primary-selected) 10%,
          var(--b-color-interactive-primary-selected) 100%
        );

        [dir='rtl'] & {
          background: linear-gradient(
            90deg,
            var(--b-color-interactive-primary-selected) 0%,
            var(--b-color-interactive-primary-selected) 90%,
            transparent 90%,
            transparent 100%
          );
        }
      }
    }

    // Día del rango cuando es el último día de la semana y éste no es checkIn ni checkOut
    .rmdp-range:last-child:not(.start, .end) {
      background: linear-gradient(
        90deg,
        var(--b-color-interactive-primary-selected) 0%,
        var(--b-color-interactive-primary-selected) 85%,
        transparent 85%,
        transparent 100%
      );

      [dir='rtl'] & {
        background: linear-gradient(
          90deg,
          transparent 0%,
          transparent 15%,
          var(--b-color-interactive-primary-selected) 15%,
          var(--b-color-interactive-primary-selected) 100%
        );
      }

      @media #{$fromTablet} {
        background: linear-gradient(
          90deg,
          var(--b-color-interactive-primary-selected) 0%,
          var(--b-color-interactive-primary-selected) 70%,
          transparent 70%,
          transparent 100%
        );

        [dir='rtl'] & {
          background: linear-gradient(
            90deg,
            transparent 0%,
            transparent 30%,
            var(--b-color-interactive-primary-selected) 30%,
            var(--b-color-interactive-primary-selected) 100%
          );
        }
      }

      @media #{$fromLaptop} {
        background: linear-gradient(
          90deg,
          var(--b-color-interactive-primary-selected) 0%,
          var(--b-color-interactive-primary-selected) 90%,
          transparent 90%,
          transparent 100%
        );

        [dir='rtl'] & {
          background: linear-gradient(
            90deg,
            transparent 0%,
            transparent 10%,
            var(--b-color-interactive-primary-selected) 10%,
            var(--b-color-interactive-primary-selected) 100%
          );
        }
      }
    }
  }
}

.errorDays {
  :global {
    // Días del rango
    .rmdp-range {
      box-shadow: unset;

      color: var(--b-color-text-dark);
      background-color: var(--b-color-support-error-light);

      .sd {
        background-color: unset;
        path {
          stroke: var(--b-color-icon-dark);
        }
      }

      // Aplicar el color negro a los dots del rango de precios
      button > .dots > * {
        background-color: var(--b-color-fill-neutral-04);
      }
    }

    // Hover de los días del rango que no son ni checkIn ni checkOut, ni están deshabilitados, ni ocultos.
    .rmdp-range:not(.rmdp-disabled, .rmdp-day-hidden, .start, .end) .sd:hover {
      background-color: unset;
    }

    // Días del rango que no son ni checkIn ni checkOut
    .rmdp-range:not(.end, .start) {
      button {
        background-color: unset;
        color: var(--b-color-text-dark);
      }
    }

    .rmdp-range.start,
    .rmdp-range.end {
      button {
        color: var(--b-color-text-light);

        // Aplicar el color blanco a los dots del rango de precios
        & > .dots > * {
          background-color: var(--b-color-fill-neutral-00);
        }
      }

      .sd {
        background-color: var(--b-color-support-error);
      }

      path {
        stroke: var(--b-color-bg-white);
      }
    }

    // Día de checkIn
    .rmdp-range.start {
      background: linear-gradient(
        90deg,
        transparent 0%,
        transparent 49%,
        var(--b-color-support-error-light) 49%,
        var(--b-color-support-error-light) 100%
      );

      [dir='rtl'] & {
        background: linear-gradient(
          90deg,
          var(--b-color-support-error-light) 0%,
          var(--b-color-support-error-light) 49%,
          transparent 49%,
          transparent 100%
        );
      }

      .sd {
        border-radius: toRem(3) 0 0 toRem(3);

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: $dayWidth;

          border-bottom: calc($dayHeight / 2) solid transparent;
          border-top: calc($dayHeight / 2) solid transparent;
          border-left: toRem(4) solid var(--b-color-support-error);

          @media #{$fromLaptop} {
            border-left: toRem(6) solid var(--b-color-support-error);
          }

          [dir='rtl'] & {
            right: $dayWidth;
            left: unset;

            border-left: unset;
            border-right: toRem(6) solid var(--b-color-support-error);
          }
        }
      }

      // Si el checkIn es el último día de la semana no le pone fondo
      &:last-child {
        background: unset;

        .sd::after {
          background-color: unset;
        }
      }
    }

    //Día de checkOut
    .rmdp-range.end {
      background: linear-gradient(
        90deg,
        var(--b-color-support-error-light) 0%,
        var(--b-color-support-error-light) 49%,
        transparent 49%,
        transparent 100%
      );

      [dir='rtl'] & {
        background: linear-gradient(
          90deg,
          transparent 0%,
          transparent 49%,
          var(--b-color-support-error-light) 49%,
          var(--b-color-support-error-light) 100%
        );
      }

      .sd {
        border-radius: 0 toRem(3) toRem(3) 0;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: $dayWidth;

          border-bottom: calc($dayHeight / 2) solid transparent;
          border-top: calc($dayHeight / 2) solid transparent;
          border-right: toRem(4) solid var(--b-color-support-error);

          @media #{$fromLaptop} {
            border-right: toRem(6) solid var(--b-color-support-error);
          }

          [dir='rtl'] & {
            right: unset;
            left: $dayWidth;

            border-left: toRem(6) solid var(--b-color-support-error);
            border-right: unset;
          }
        }
      }

      // Si el checkOut es el primer día de la semana no le pone fondo
      &:first-child {
        background: unset;

        .sd::before {
          background-color: unset;
        }
      }
    }

    // Día del rango cuando es el primer día de la semana y éste no es checkIn ni checkOut
    .rmdp-range:first-child:not(.start, .end) {
      background: linear-gradient(
        90deg,
        transparent 0%,
        transparent 15%,
        var(--b-color-support-error-light) 15%,
        var(--b-color-support-error-light) 100%
      );

      [dir='rtl'] & {
        background: linear-gradient(
          90deg,
          var(--b-color-support-error-light) 0%,
          var(--b-color-support-error-light) 85%,
          transparent 85%,
          transparent 100%
        );
      }

      @media #{$fromTablet} {
        background: linear-gradient(
          90deg,
          transparent 0%,
          transparent 30%,
          var(--b-color-support-error-light) 30%,
          var(--b-color-support-error-light) 100%
        );

        [dir='rtl'] & {
          background: linear-gradient(
            90deg,
            var(--b-color-support-error-light) 0%,
            var(--b-color-support-error-light) 70%,
            transparent 70%,
            transparent 100%
          );
        }
      }

      @media #{$fromLaptop} {
        background: linear-gradient(
          90deg,
          transparent 0%,
          transparent 10%,
          var(--b-color-support-error-light) 10%,
          var(--b-color-support-error-light) 100%
        );

        [dir='rtl'] & {
          background: linear-gradient(
            90deg,
            var(--b-color-support-error-light) 0%,
            var(--b-color-support-error-light) 90%,
            transparent 90%,
            transparent 100%
          );
        }
      }
    }

    // Día del rango cuando es el último día de la semana y éste no es checkIn ni checkOut
    .rmdp-range:last-child:not(.start, .end) {
      background: linear-gradient(
        90deg,
        var(--b-color-support-error-light) 0%,
        var(--b-color-support-error-light) 85%,
        transparent 85%,
        transparent 100%
      );

      [dir='rtl'] & {
        background: linear-gradient(
          90deg,
          transparent 0%,
          transparent 15%,
          var(--b-color-support-error-light) 15%,
          var(--b-color-support-error-light) 100%
        );
      }

      @media #{$fromTablet} {
        background: linear-gradient(
          90deg,
          var(--b-color-support-error-light) 0%,
          var(--b-color-support-error-light) 70%,
          transparent 70%,
          transparent 100%
        );

        [dir='rtl'] & {
          background: linear-gradient(
            90deg,
            transparent 0%,
            transparent 30%,
            var(--b-color-support-error-light) 30%,
            var(--b-color-support-error-light) 100%
          );
        }
      }

      @media #{$fromLaptop} {
        background: linear-gradient(
          90deg,
          var(--b-color-support-error-light) 0%,
          var(--b-color-support-error-light) 90%,
          transparent 90%,
          transparent 100%
        );

        [dir='rtl'] & {
          background: linear-gradient(
            90deg,
            transparent 0%,
            transparent 10%,
            var(--b-color-support-error-light) 10%,
            var(--b-color-support-error-light) 100%
          );
        }
      }
    }
  }
}
