$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.dot {
  border-radius: var(--b-border-radius-50-percent);
  width: toRem(4);
  height: toRem(4);
}

.dot_low {
  background-color: var(--b-color-support-success);
}

.dot_medium {
  background-color: var(--b-color-support-warning);
}

.dot_high {
  background-color: var(--b-color-support-error);
}
