$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.calendarFooter {
  height: 100%;
  padding-top: var(--b-space-m);
  padding-bottom: toRem(135); //135px es el alto del footer en mobile

  @media #{$fromLaptop} {
    margin-top: var(--b-space-l);
    border-top: unset;
    padding: unset;
  }
}

.calendarFooterContent {
  position: fixed;
  z-index: var(--b-z-index-2);
  bottom: 0;
  left: 0;

  border-top: var(--b-border-radius-1) solid var(--b-color-divider);
  width: 100%;
  padding: var(--b-space-m) var(--b-space-m) var(--b-space-l) var(--b-space-m);

  background-color: var(--b-color-bg-white);

  @media #{$fromLaptop} {
    position: unset;

    flex-direction: row;

    margin: auto;
    padding: unset;
    padding-top: var(--b-space-l);

    background-color: unset;
  }
}

.footerAlert {
  bottom: var(--b-space-m);

  width: calc(100% - 2 * var(--b-space-m));

  @media #{$fromLaptop} {
    width: 100%;
  }
}

.footerDatesWrapper {
  display: flex;
  gap: var(--b-space-xs);
  flex-direction: row;
  align-items: center;

  @media #{$fromLaptop} {
    gap: var(--b-space-m);
  }
}

.datesContainer {
  width: 100%;

  @media #{$fromLaptop} {
    gap: var(--b-space-l);
    justify-content: flex-start;
    align-items: flex-end;

    border-top: unset;
    padding-top: unset;
  }
}

.checkInWrapper,
.checkOutWrapper {
  text-align: start;
}

.selectTitle {
  border-bottom: var(--b-border-radius-2) solid var(--b-color-divider);
}

.footerButtons {
  width: 100%;
  max-height: toRem(40);

  @media #{$fromLaptop} {
    gap: var(--b-space-m);
    justify-content: flex-end;
  }
}

.nightsTag {
  background-color: var(--b-color-support-info-light);
}

.errorNightsTag {
  background-color: var(--b-color-support-error-light);
}

.confirmButton {
  min-width: toRem(195);
  min-height: toRem(40);
}

.resetButton {
  color: var(--b-color-secondary-link);
}

.legendPriceRangeWrapper {
  position: relative;

  flex-grow: 1;

  margin: 0 calc(var(--b-space-m) * -1);
  border-top: var(--b-border-radius-1) solid var(--b-color-divider);
  width: calc(100% + 2 * var(--b-space-m));
  padding: var(--b-space-m);

  @media #{$fromLaptop} {
    margin: unset;
    border-top: unset;
    width: 100%;
    padding: var(--b-space-m) 0;
  }
}

.alertReturnStep1 {
  text-align: start;
}

.groupCodeRestrictionAlert {
  text-align: start;
}

.disabled {
  pointer-events: none;
  color: var(--b-color-text-disabled);
}
